<template>
  <v-main>
    <v-card flat>
      <v-toolbar
        flat
        color="white"
      >
        <v-row>
          <v-col cols="12" md="6">
            <v-toolbar-title>
              Заказ № {{ orderId }} от {{ order.created | dateformat }} {{ subTitle }}
            </v-toolbar-title>
          </v-col>
          <v-col cols="12" md="6" class="text-right">
            <router-link :to="{ name: 'OrderAdminEdit', params: { id: order.id } }">
              <v-btn
                color="success"
                class="mr-2"
                depressed
                v-if="canChangeAdminOrder"
              >
                <v-icon
                  left
                >
                  mdi-eye
                </v-icon>
                Настроить заказ
              </v-btn>
            </router-link>
            <v-btn
              v-if="showInvoiceButton"
              :disabled="itemsLoading"
              :href="getOrderInvoicePDF"
              class="mr-2"
              color="primary"
              target="_blank"
              depressed
            >
              <v-icon left>
                mdi-download
              </v-icon>
              Счет на оплату
            </v-btn>
            <v-btn
              v-if="!order.is_marketplace"
              :disabled="itemsLoading"
              :href="getOrderPDF"
              class="mr-2"
              color="primary"
              target="_blank"
              depressed
            >
              <v-icon left>
                mdi-download
              </v-icon>
              Спецификация
            </v-btn>
            <v-btn
              v-show="order.type === 'FBS' && order.status === 'fbs_processing'"
              class="mr-2"
              color="yellow"
              @click="patchOrder()"
            >
              <v-icon left>
                mdi-arrow-up-bold-box-outline
              </v-icon>
              Отправить заказ
            </v-btn>
          </v-col>
        </v-row>

      </v-toolbar>
      <v-list>
        <v-list-item v-if="isStaff">
          <v-list-item-content>
            <b>Компания:</b>
            {{ order.company }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <b>Дата создания:</b>
            {{ order.created | dateformat }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="order.completed">
          <v-list-item-content>
            <b>Дата выполнения:</b>
            {{ order.completed | dateformat }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <b>Статус:</b>
            {{ order.status_display }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="!order.is_marketplace"
        >
          <v-list-item-content>
            <b>Статус оплаты:</b>
            {{ order.payment_status_display }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="order.note">
          <v-list-item-content>
            <b>Комментарий:</b>
            <p v-html="order.note" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-title>Список товаров</v-card-title>
      <v-data-table
        :headers="headersItems"
        :items="order.items"
        item-key="id"
        dense
        flat
        disable-pagination
        hide-default-footer
        :loading="itemsLoading"
      >
        <template #[`item.amount_usd`]="{ item }">
          {{
            item.amount_usd | currency(`USD`)
          }}
        </template>
        <template #[`item.price_usd`]="{ item }">
          {{
            item.price_usd | currency(`USD`)
          }}
        </template>
        <template #[`item.note`]="{ item }">
          <v-tooltip
            v-if="item.note"
            bottom
            max-width="400"
          >
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                color="blue"
                v-on="on"
              >
                mdi-comment
              </v-icon>
            </template>
            <span>{{ item.note }}</span>
          </v-tooltip>
          <v-icon
            v-else
            color="gray"
          >
            mdi-comment
          </v-icon>
        </template>
        <template #[`body.append`]="{ headers }">
          <tr v-if="$vuetify.breakpoint.name == 'xs'">
            <th>
              <v-row>
                <v-col cols="6">
                  Итого
                </v-col>
                <v-col
                  class="text-right"
                  cols="6"
                >
                  {{
                    order.amount | currency(`USD`)
                  }}
                </v-col>
              </v-row>
            </th>
          </tr>
          <tr v-else>
            <th>Итого</th>
            <th :colspan="headers.length - 5"/>
            <th>{{ order.quantity }}</th>
            <th/>
            <th>{{ order.amount | currency(`USD`) }}</th>
            <th/>
          </tr>
        </template>
      </v-data-table>
      <v-card-title
        v-if="!order.is_marketplace"
      >
        Список оплат
      </v-card-title>
      <v-data-table
        v-if="!order.is_marketplace"
        :headers="headersPayments"
        :items="order.payments"
        item-key="id"
        dense
        disable-pagination
        hide-default-footer
        :loading="itemsLoading"
      >
        <template #[`item.amount`]="{ item }">
          {{
            item.amount | currency(`USD`)
          }}
        </template>
        <template #[`item.created`]="{ item }">
          {{
            item.created | dateformat
          }}
        </template>
      </v-data-table>
    </v-card>
  </v-main>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import stocks from "@/api/stocks";
import router from "@/router";

export default {
  name: "OrderView",
  data: () => ({
    order: {},
    itemsLoading: true,
    stock: "",
    paymentStatus: "100",
    paymentOptions: [
      {text: "Оплачено 100%", value: "paid_100"},
      {text: "Оплачено 70%", value: "paid_70"},
      {text: "Оплачено 50%", value: "paid_50"},
      {text: "Не оплачено", value: "not_paid"}
    ],
  }),
  computed: {
    ...mapGetters("auth", ["isStaff", "isWholesaleFarmer", "isWholesaleROP", "isWholesaleHunter", "isPurchasingManager", "isDirectorOfFranchising", "isAdmin", "isSuperUser", "isStorekeeper"]),
    ...mapGetters("clients", ["getStockList"]),
    ...mapGetters("stocks", [
      "getOrderFromChina",
    ]),
    headersItems() {
      let headers = [
        {text: "Артикул", value: "product.vendor_code", isMarketplaceManager: false},
        {text: "Наименование", value: "product.title"},
        {text: "Штрих-код", value: "product.bar_code", isMarketplaceManager: true},
        {text: "Артикул", value: "product.vendor_code", isMarketplaceManager: true},
        {text: "Количество", value: "quantity"},
        {text: "Стоимость", value: "price_usd"},
        {text: "Стоимость позиции", value: "amount_usd"},
        {text: "Комментарий", value: "note"},
      ];
      return this.order.is_marketplace
        ? headers.filter((el) => el.isMarketplaceManager !== false)
        : headers.filter((el) => el.isMarketplaceManager !== true);
    },
    headersPayments() {
      return [
        {text: "Сумма", value: "amount"},
        {text: "Дата", value: "created"},
      ];
    },
    orderId() {
      return this.$route.params.id;
    },
    subTitle() {
      if (this.order.is_marketplace) {
        return this.order.type + " " + this.order.stock_to
      } else {
        if (this.order.from_china) {
          return "(с производства)";
        } else {
          return "(со склада)";
        }
      }
    },
    getOrderPDF() {
      return `${this.$store.state.baseURL}/stocks/order/${this.orderId}/pdf/?token=${this.$store.state.auth.token}`;
    },
    showInvoiceButton() {
      return this.isWholesaleFarmer || this.isWholesaleROP || this.isWholesaleHunter;
    },
    getOrderInvoicePDF() {
      return `${this.$store.state.baseURL}/stocks/order/${this.orderId}/pdf/?token=${this.$store.state.auth.token}&invoice=True`;
    },
    canChangeAdminOrder() {
      return this.isAdmin || this.isDirectorOfFranchising || this.isSuperUser || this.isWholesaleROP || this.isStorekeeper;
    },
  },
  watch: {
    stock() {
      if (!this.getLoadingState) this.getStocksItems();
    },
    getUserCompany() {
      this.pStock = "";
      this.getStocksItems();
    },
  },
  created() {
    stocks
      .getAdminOrder(this.orderId)
      .then((response) => {
        this.order = response.data;
        this.itemsLoading = false;
      })
      .catch((error) => this.$store.dispatch("alertError", error));
    this.updateStockList(); // Для того чтобы в заглавии видеть название склада
  },
  methods: {
    ...mapActions("clients", ["updateStockList"]),
    ...mapActions("stocks", ["clearOrder"]),
    deleteOrder() {
      if (confirm("Удалить заказ? Это действие необратимо!")) {
        stocks
          .deleteOrder(this.orderId)
          .then(() => {
            this.clearOrder(); // Чтобы случайно не попадали в окно редактирования заявки
            this.$store.dispatch("alertSuccess", "Заказ удалён");
            this.$router.push({name: "OrderList"});
          })
          .catch((error) => this.$store.dispatch("alertError", error));
      }
    },
    getPrice() {
      if (this.getOrderFromChina) {
        return 'price_from_china';
      } else {
        return 'price';
      }
    },
    patchOrder() {
      stocks.patchOrder(
        {id: this.orderId, status: 'new'}).then(
        response => {
          router.push({name: 'OrderView', params: {id: response.data.id}});
          location.reload();
          this.$store.dispatch('alertSuccess', 'Заказ успешно отправлен', {root: true});
          this.$store.dispatch('clearOrder');
        }
      ).catch(error => {
        if (error.response.data.items) error.response.data.items.forEach(el => this.$store.dispatch('alertError', el, {root: true}));
      });
    },
  },
};
</script>
